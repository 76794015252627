export type NavigationItem = {
  title: string
  icon: string
  link: string
  items: { title: string; link: string }[]
}

export const navItems: NavigationItem[] = [
  {
    title: '新規予約',
    icon: 'mdi-calendar',
    link: '/reservation/',
    items: []
  },
  {
    title: '予約確認/変更',
    icon: 'mdi-account',
    link: '/',
    items: [
      { title: '予約一覧', link: '/reservations/list/' },
      { title: '過去の予約', link: '/reservations/history/' }
    ]
  },
  {
    title: '設定',
    icon: 'mdi-account',
    link: '/',
    items: [
      { title: 'アカウント確認/変更', link: '/account/overview/' },
      { title: 'パスワード変更', link: '/account/password/' },
      { title: '通知の設定', link: '/account/notification/' }
    ]
  },
  {
    title: 'サービス情報',
    icon: 'mdi-account',
    link: '/',
    items: [
      { title: 'お知らせ', link: '/service/news/' },
      // { title: '通知履歴', link: '/service/notifications/' },
      { title: '利用規約', link: '/service/terms/' },
      { title: 'プライバシーポリシー', link: '/service/privacy/' },
      // { title: 'ソフトウェアライセンス', link: '/service/license/' },
      { title: 'お問い合わせ', link: '/service/contact/' },
      { title: 'クーポン', link: '/service/coupon/' }
    ]
  }
]
